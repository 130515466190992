/* tslint:disable */
/* eslint-disable */
/**
 * BasisWebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    RoleAddUserRequest,
    RoleAddUserRequestFromJSON,
    RoleAddUserRequestToJSON,
    RoleCreateRequest,
    RoleCreateRequestFromJSON,
    RoleCreateRequestToJSON,
    RoleDetailedResponse,
    RoleDetailedResponseFromJSON,
    RoleDetailedResponseToJSON,
} from '../models';

export interface RolesApiAddUserRequest {
    roleAddUserRequest?: RoleAddUserRequest;
}

export interface RolesApiCreateRequest {
    roleCreateRequest?: RoleCreateRequest;
}

/**
 * 
 */
export class RolesApi extends runtime.BaseAPI {

    /**
     */
    async addUserRaw(requestParameters: RolesApiAddUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoleDetailedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Roles/addUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleAddUserRequestToJSON(requestParameters.roleAddUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async addUser(requestParameters: RolesApiAddUserRequest, initOverrides?: RequestInit): Promise<RoleDetailedResponse> {
        const response = await this.addUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createRaw(requestParameters: RolesApiCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RoleDetailedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Roles/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleCreateRequestToJSON(requestParameters.roleCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async create(requestParameters: RolesApiCreateRequest, initOverrides?: RequestInit): Promise<RoleDetailedResponse> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RoleDetailedResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Roles/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleDetailedResponseFromJSON));
    }

    /**
     */
    async list(initOverrides?: RequestInit): Promise<Array<RoleDetailedResponse>> {
        const response = await this.listRaw(initOverrides);
        return await response.value();
    }

}
