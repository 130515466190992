import React from 'react'

import { Home } from '../components/Home';
import { Counter } from '../components/Counter';
import { FetchData } from '../components/FetchData';

// Authentication
import Login from '../components/authentication/Login';
import Logout from '../components/authentication/Logout';
import Register from '../components/authentication/Register';

import Imprint from '../components/Imprint';
import DataProtection from '../components/DataProtection';



const AppRoutes = {
  Home: { element: <Home />, routeProps: { index: true } },
  Counter: { element: <Counter />, routeProps: { path: 'counter' } },
  FetchData: { element: <FetchData />, routeProps: { path: 'fetch-data' } },

  
  Authentication : {
    isIndex: true, routeProps: { path: 'Authentication' },
    subRoutes : {
      Login: { element: <Login/>, routeProps: { path: 'Login' } }, 
      Register: { element: <Register/>, routeProps: { path: 'Register' } }, 
      LogOut: { element: <Logout/>, routeProps: { path: 'Logout' } }, 
    }
  },

  Account : {
    isIndex: true, routeProps: { path: 'Account' },
    subRoutes : {
      Profile: { element: <h1>Profile</h1>, routeProps: { path: 'Profile' } }, 
      ForgotPassword: { element: <h1>ForgotPassword</h1>, routeProps: { path: 'ForgotPassword' } }, 
    }
  },

  Imprint: { element: <Imprint />, routeProps: { path: 'Impressum' } },
  DataProtection: { element: <DataProtection />, routeProps: { path: 'Datenschutz' } },
};

export function createFullPath(key, subkey) {
  return "/" + AppRoutes[key].routeProps.path + "/" + AppRoutes[key].subRoutes[subkey].routeProps.path;
} 

export default AppRoutes;
