import React, { Component } from 'react';
import ApiBackend from './api-backend/ApiBackend';

export class FetchData extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      unprotected: [], 
      auth: [], 
      user: [], 
      admin: [], 
      adminOrUser: [], 
      loading: true 
    };
  }

  componentDidMount() {
    this.populateData();
  }

  static renderTables(state) {
    return(
      <>
        {FetchData.renderTable("Unprotected", state.unprotected)}
        {FetchData.renderTable("Auth", state.auth)}
        {FetchData.renderTable("User", state.user)}
        {FetchData.renderTable("Admin", state.admin)}
        {FetchData.renderTable("Admin or User", state.adminOrUser)}
      </>
    );
  }

  static renderTable(title, data) {
    return (
      <>
        <hr />
        <h3>{title}</h3>
        {data.status === undefined ? FetchData.renderTableContent(data) : 
          <>
            <h5>Http Error Status: {data.status}</h5>
          </>
        }
        <br />
      </>
    );
  }

  static renderTableContent(data) {
    return(
      <table className='table table-striped' aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Auth</th>
              <th>User</th>
              <th>Admin</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data.auth ? "true" : "false"}</td>
              <td>{data.user ? "true" : "false"}</td>
              <td>{data.admin ? "true" : "false"}</td>
            </tr>
          </tbody>
        </table>
    );
  }

  render() {  
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchData.renderTables(this.state);

    return (
      <div>
        <h1 id="tabelLabel" >Accessed Data from protected API Endpoint</h1>
        <p>This component demonstrates fetching data from the server.</p>
        {contents}
      </div>
    );
  }

  async populateData() {
    var api = ApiBackend.Protected; 

    var unprotected;
    try {      unprotected = await api.unprotected(); }
    catch(e) { unprotected = e; }

    var auth;
    try {      auth = await api.auth(); }
    catch(e) { auth = e; }

    var user;
    try {        user = await api.user();
    } catch(e) { user = e; }

    var admin;
    try {      admin = await api.admin(); }
    catch(e) { admin = e; }

    var adminOrUser;
    try {      adminOrUser = await api.adminOrUser(); }
    catch(e) { adminOrUser = e; }


    this.setState({ 
      unprotected: unprotected, 
      auth: auth,
      user: user,
      admin: admin,
      adminOrUser: adminOrUser,
      loading: false });
  }
}
