import { Configuration } from "./runtime";
import authServics from "../authorization/AuthorizeService";
import { backendConfig } from "./config";
import * as Apis from "./apis";

export class ApiBackendClass {
  // CODEGEN <Declaring>
  public Accounts : Apis.AccountsApi;
  public Auth : Apis.AuthApi;
  public Protected : Apis.ProtectedApi;
  public Roles : Apis.RolesApi;
  public Version : Apis.VersionApi;
  // CODEGEN </Declaring>

  constructor() {
    let configuration = new Configuration({ ...backendConfig,
      "accessToken" : authServics.getAccessToken,
    });

    // CODEGEN <Instantiating>
    this.Accounts = new Apis.AccountsApi(configuration);
    this.Auth = new Apis.AuthApi(configuration);
    this.Protected = new Apis.ProtectedApi(configuration);
    this.Roles = new Apis.RolesApi(configuration);
    this.Version = new Apis.VersionApi(configuration);
    // CODEGEN </Instantiating>
  } // constructor

} // export class ApiBackendClass


const ApiBackend = new ApiBackendClass();
export default ApiBackend;